@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter Regular";
  src: url("./assets/fonts/Inter-Regular.ttf");
}

@font-face {
  font-family: "Inter Bold";
  src: url("./assets/fonts/Inter-Bold.ttf");
}
@font-face {
  font-family: "Inter SemiBold";
  src: url("./assets/fonts/Inter-SemiBold.ttf");
}

@font-face {
  font-family: "Inter Medium";
  src: url("./assets/fonts/Inter-Medium.ttf");
}

@layer base {
  html {
    font-family: "Inter Regular", system-ui, sans-serif;
    @apply text-primaryText-1;
    scroll-behavior: smooth;
  }
}


/* Carousel */

.slick-slide {
  padding: 18px;
}

.slick-prev::before,
.slick-next::before {
  display: none;
}

.slick-arrow.slick-prev {
  z-index: 99;
  left: 0
}

.slick-arrow.slick-next {
  right: 0
}

/* Scrollbar */

.Modal::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
.Modal::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #EAECF0;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-text-fill-color: #5c5c65;
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset;
  box-shadow: 0 0 0 1000px #ffffff inset;
  color: #5c5c65;
}
